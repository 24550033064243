import { Link } from 'gatsby';
import React, { FC } from 'react';
import Logo from '../images/logo.svg';

const Navbar: FC = () => (
  <>
    <nav className="bg-primary shadow-[0_10px_5px_0_rgba(255,255,255,0.1)] page-content h-[100%] flex items-center justify-between p-4 px-12">
      <div className="flex items-center align-center">
        <Link to="/" aria-label="logo" className="w-[12rem]">
          <div className='w-[8rem] md-w-[12rem]'>
            <Logo />
          </div>
        </Link>
      </div>
      <div className="flex items-center justify-end whitespace-nowrap">
        <ul className="navbar-list">
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </nav>
  </>
);

export default Navbar;
