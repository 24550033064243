import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer
      id="footer"
      className="bg-primary h-[120px] flex items-center shadow-[0_-10px_15px_-3px_rgba(255,255,255,0.1)] p-4">
      <div className="page-content flex flex-col p-0 items-center">
        <p className=" text-white font-medium">
          © 2021 - {new Date().getFullYear()} {t('footer.terms')} Madalit OU
        </p>
      </div>
    </footer>
  );
};

export default Footer;
