import React, { FC } from 'react';
import LayoutProps from 'types/layout';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Layout: FC<LayoutProps> = ({ children }) => (
  <>
    <NavBar />
    <main className="flex flex-1 page-content bg">{children}</main>
    <Footer />
  </>
);

export default Layout;
